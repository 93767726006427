@import "../../styles/theme";

.std-upload-card {
  padding: 2rem 0px;
  .image-container {
    margin: 4px 0px;
  }
  .step-title {
    @include typography(
      $font-secondary,
      $secondary-button-font-color,
      $size: 1.5rem,
      $weight: bold
    );
    margin: 4px 0px;
    line-height: 1;
  }
  .step-subtitle {
    @include typography($font-primary, $container-upload-card-subtitle-color);
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    line-height: 1;
  }
  .upload-notes {
    @include typography(
      $font-primary,
      $container-upload-card-notes-color,
      $size: 1.2rem
    );
    margin-top: 1.2rem;
    line-height: 1.5;
  }
  .btn-container {
    display: flex;
    align-items: center;
    .upload-status {
      display: flex;
      .filename {
        @include typography(
          $font-primary,
          $container-upload-card-subtitle-color
        );
        display: flex;
        align-items: center;
        padding: 0px 0.8rem 0px 2.4rem;
      }
      .filename1 {
        @include typography(
          $font-primary,
          $container-upload-card-notes-color,
          $size: 0.8rem
        );
        display: flex;
        align-items: center;
        padding: 0px 0.8rem 0px 2.4rem;
      }
      &.hidden {
        display: none;
      }
    }
  }
}
