@import "../../../styles/theme";

.std-secondary-button {
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  @include typography(
    $font-primary,
    $color: $secondary-button-font-color,
    $weight: 600
  );
  background-color: $secondary-button-background-color;
  &[disabled] {
    background-image: none;
    color: $secondary-button-disabled-font-color;
    cursor: not-allowed;
    box-shadow: none;
  }
  &:focus,
  &:hover {
    box-shadow: none;
    background-image: none;
    outline: none;
  }
  &.large {
    padding: 1.4rem 5rem;
  }
  &.small {
    padding: 1.4rem 5rem;
  }
  &.auto {
    padding: 1.4rem 2.5rem;
    width: auto;
  }
}
