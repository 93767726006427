@import "../../styles/theme";

.logout-div {
  position: absolute;
  top: 10px;
  right: 10px;
}

.logout-button {
  width: 90px;
  height: 40px;
  border-radius: 4px;
  background-color: #1439da;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;

  cursor: pointer;
  @include typography(
    $font-primary,
    $primary-button-font-color,
    $size: 16px,
    $weight: 600
  );
}
