@font-face {
  font-family: "Inter";
  font-weight: normal;
  font-display: swap;
  src: url("../assets/fonts/Inter/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter";
  font-weight: 600;
  font-display: swap;
  src: url("../assets/fonts/Inter/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter";
  font-weight: bold;
  font-display: swap;
  src: url("../assets/fonts/Inter/Inter-Bold.ttf");
}
@font-face {
  font-family: "Ubuntu";
  font-weight: bold;
  font-display: swap;
  src: url("../assets/fonts/Ubuntu/Ubuntu-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo";
  font-weight: normal;
  font-display: swap;
  src: url("../assets/fonts/Cairo/Cairo-Regular.ttf");
}
@font-face {
  font-family: "Cairo";
  font-weight: 600;
  font-display: swap;
  src: url("../assets/fonts/Cairo/Cairo-SemiBold.ttf");
}
@font-face {
  font-family: "Cairo";
  font-weight: bold;
  font-display: swap;
  src: url("../assets/fonts/Cairo//Cairo-Bold.ttf");
}
