@import "../../../styles/theme";

.unauthorized-input {
  .input-container {
    position: relative;
    background-color: #f7f7f7;
    padding: 20px 16px;
    border-bottom: 0.5px solid #12161a;
    border-radius: 3px 3px 0 0;
    display: flex;
    align-items: center;
    font-family: "Inter";
    .icon {
      img {
        max-width: 24px;
      }
    }
    &:focus-within {
      border-color: #1439da;
    }
    &.error {
      border-color: #d4111b;
    }
    .input-wrapper {
      width: 100%;
      position: relative;
      &-icon {
        width: 100%;
        position: relative;
        margin-left: 16px;
      }
    }
    label {
      cursor: text;
      line-height: 1.25;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      transition: 0.5s;
      color: #919191;
    }
    input {
      width: 100%;
      background-color: transparent;
      border: none;
      padding: 0;
      font-size: 16px;
      line-height: 1.5;
      color: #191c1f;
      filter: none;
    }
    input::-webkit-contacts-auto-fill-button,
    input::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      height: 0;
      width: 0;
      margin: 0;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
    }
    input::placeholder {
      color: transparent;
    }
    input:focus ~ label,
    input:not(:placeholder-shown) ~ label {
      font-size: 12px;
      color: #1439da;
      top: -8px;
    }
    input:-webkit-autofill ~ label {
      font-size: 12px;
      color: #1439da;
      top: -8px;
    }
    input:-moz-autofill ~ label {
      font-size: 12px;
      color: #1439da;
      top: -8px;
    }
    &.error {
      input:focus ~ label,
      input:not(:placeholder-shown) ~ label {
        color: #d4111b;
      }
      input:-webkit-autofill ~ label {
        color: #d4111b;
      }
      input:-moz-autofill ~ label {
        color: #d4111b;
      }
    }
  }
  .error-message {
    font-family: Inter;
    margin-top: 4px;
    line-height: 1.5;
    color: #d4111b;
    font-size: 12px;
    padding-left: 16px;
    a {
      color: #1439da;
    }
    &-icon {
      padding-left: 58px;
    }
  }
}

.ar-AE {
  .unauthorized-input {
    .input-container {
      .input-wrapper {
        &-icon {
          margin-left: 0;
          margin-right: 16px;
        }
      }
      label {
        right: 0;
        left: auto;
      }
    }
    .error-message {
      padding-left: 0;
      padding-right: 16px;
      &-icon {
        padding-right: 58px;
      }
    }
  }
}
