
@import '../../styles/colors';


#std-header {
  width: 100%;
  height: 70px;
  background-color: $white;
  border-bottom: solid 1px $gray-level-8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 35px;
  .logo {
    max-width: 130px;
    width: 130px;
  }

}