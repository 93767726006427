@import "./palette";

$white: #ffffff;
$blue: #1439da;
$violet: #000fff;
$red: red;
$gray-level-8: #eaeaea;
$black: $black;
$primary-font-color: $white;
$primary-button-font-color: $white;
$primary-button-background-color: $blue1;
$primary-button-disabled-background-color: $blue2;
$primary-button-disabled-font-color: $blue3;
$secondary-button-background-color: $white;
$secondary-button-font-color: $black1;
$secondary-button-disabled-font-color: $gray17;
$upload-button-border-color: $gray18;
$modal-backdrop-color: $black1;
$modal-background-color: $black3;
$container-upload-border-color: $gray1;
$container-upload-card-subtitle-color: $gray23;
$container-upload-card-notes-color: $gray22;
$container-upload-warning-modal-subtitle-color: $gray23;
$container-upload-warning-modal-button-color: $white;
