@import "../../styles/colors";

#login-page {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  .login-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .login-wrapper {
    width: 100%;
    max-width: 350px;
  }
  h2 {
    font-family: "Ubuntu";
    font-size: 28px;
    font-weight: bold;
    line-height: 1.5;
    color: #191c1f;
    margin-bottom: 32px;
    text-align: center;
  }
  form {
    margin-bottom: 16px;
    .login-field {
      margin-bottom: 24px;
    }
    .submit {
      width: 100%;
      padding: 16px 0;
      background-color: #1439da;
      font-size: 16px;
      font-weight: 600;
      &:disabled {
        background-color: #d0d7f8;
      }
    }
  }
  .forgot-password {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    color: #1439da;
    margin-bottom: 48px;
    display: block;
    text-align: center;
  }
  .registration-question {
    font-family: "Inter";
    margin-bottom: 16px;
    font-size: 14px;
    display: block;
    text-align: center;
  }

  .registration-btn {
    text-align: center;
    font-family: "Inter";
    display: block;
    font-weight: 600;
    width: 100%;
    padding: 12px 0;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #ffffff;
    color: #191c1f;
  }
  .error {
    font-family: "Inter";
    margin-top: 12px;
    font-size: 14px;
    color: $red;
  }
  input:focus {
    outline: none;
  }
}
