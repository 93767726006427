@mixin font-size($size) {
  font-size: $size;
}

@mixin typography(
  $family,
  $color: $primary-font-color,
  $size: 1.4rem,
  $weight: normal
) {
  font-family: $family;
  font-size: $size;
  color: $color;
  font-weight: $weight;
}
