@import "../../styles/colors";
@import "../../styles/mixins";
.std-button {
  font-weight: 600;
  line-height: 1.67;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  color: white;
  &.gradient {
    background-color: $violet;
    background-image: linear-gradient(105deg, $blue, $violet);
  }
  &.primary {
    background-color: $blue;
  }
  &.secondary {
    color: $blue;
    border: 1px solid $blue;
    background-color: white;
  }
  &[disabled] {
    background-image: none;
    background-color: #d0d7f8;
    cursor: not-allowed;
    box-shadow: none;
    color: $white;
  }
  &:focus,
  &:hover {
    box-shadow: none;
    background-image: none;
    outline: none;
  }
  &.large {
    padding: 10px 50px;
    font-size: 18px;
  }
  &.small {
    font-size: 14px;
    padding: 8px 50px;
    img {
      display: none;
    }
  }
  &.rtl {
    img {
      transform: rotate(-180deg);
      margin-left: 0;
      margin-right: 10px;
    }
  }
  img {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    margin-left: 10px;
  }
}
