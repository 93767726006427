@import "../../styles/theme";

$upload-footer-height: 7.8rem;
#page-upload {
  margin-top: 1.8rem;
  margin-left: 2%;
  padding-bottom: 12rem;
  // width: 30%;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;

  .content {
    .title {
      margin-top: 1rem;
      @include typography($font-secondary, $black, $size: 2rem, $weight: bold);
      margin-bottom: 0.8rem;
      line-height: 1;
    }
    .upload-front {
      border-bottom: 2px solid $container-upload-border-color;
    }
  }
  .skeleton-loader {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    &.image {
      max-width: 4rem;
    }
    &.btn {
      max-width: 10rem;
    }
  }
  .footer {
    bottom: 0px;
    height: $upload-footer-height;

    padding: 0.9rem 0px 2rem 0px;
    box-sizing: border-box;
    box-shadow: 0 -2px 0.4rem 0 white;
    background-color: white;

    .continue-btn {
      width: 100%;
      font-size: 23px;
      font-weight: 600;
      line-height: 1.67;
      border-radius: 4px;
      border: none;
      font-family: "Inter";
    }
    .btn-loader {
      margin-top: 0;
    }
  }
}
