@import "../../../styles/theme";

.std-upload-button {
  width: auto;
  height: 3.8rem;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $upload-button-border-color;
  border-radius: 4px;
  cursor: pointer;
  background-color: $white;
  &[disabled] {
    background-color: $gray23;
    color: $white;
    border: 1px solid $gray23;
    cursor: not-allowed;
    box-shadow: none;
  }
  @include typography($font-primary, $upload-button-border-color, $weight: 600);

  img {
    padding-right: 0.8rem;
  }
}

html[dir="rtl"] {
  .std-upload-button {
    img {
      padding-right: 0;
      padding-left: 0.8rem;
    }
  }
}
