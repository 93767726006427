$white: #ffffff;
$gray1: #d0d7f8;
$gray17: #919191;
$gray18: #1439da;
$gray22: #889898;
$gray23: #c8c8c8;
$black: #000000;
$black1: #191c1f;
$black3: #252736;
$blue1: #1439da;
$blue2: #0e269a;
$blue3: #3a5ad9;
