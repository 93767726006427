@import "../../../styles/theme";

.std-primary-button {
  color: white;
  background-color: #1439da;
  cursor: pointer;
  @include typography($font-primary, $primary-button-font-color, $weight: 600);
  &[disabled] {
    background-color: #1439da;
    color: $white;
    cursor: not-allowed;
    box-shadow: none;
  }
  &:focus,
  &:hover {
    box-shadow: none;
    background-image: none;
    outline: none;
  }
  &.large {
    @include typography(
      $font-primary,
      $color: null,
      $size: 1.8rem,
      $weight: 600
    );
    padding: 0rem 5rem;
  }
  &.small {
    @include typography(
      $font-primary,
      $color: null,
      $size: 1.6rem,
      $weight: 600
    );
    padding: 20px 70px 20px 70px;
  }
  &.auto {
    @include typography(
      $font-primary,
      $color: null,
      $size: 1.6rem,
      $weight: 600
    );
    padding: 0rem 2.5rem;
    width: auto;
  }
}
