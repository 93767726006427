@import "../../styles/variables";
@import "../../styles/theme";

.std-modal {
  .hidden {
    display: none;
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    opacity: 0.6;
    background-color: $modal-backdrop-color;
  }
  .modal-content {
    width: calc(100% - #{2 * $container-horizontal-padding});
    overflow-y: auto;
    max-width: 500px;
    height: fit-content;
    max-height: 80%;
    z-index: 10001;
    text-align: center;
    background-color: $modal-background-color;
    border-radius: 14px;
    .close {
      position: absolute;
      right: 18px;
      top: 18px;
      width: 13px;
      height: 13px;
      &.hidden {
        display: none;
      }
    }
  }
  .center {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

html[dir="rtl"] {
  .std-modal {
    .modal-content {
      .close {
        right: unset;
        left: 18px;
      }
    }
  }
}
