@import "../../styles/theme";

.std-upload-warning-modal {
  padding: 2.4rem;
  img {
    margin-top: 0.8rem;
  }
  .modal-title {
    @include typography($font-secondary, $size: 2rem, $weight: bold);
    line-height: 1.5;
    margin-top: 0.8rem;
  }
  .modal-subtitle {
    @include typography(
      $font-primary,
      $container-upload-warning-modal-subtitle-color
    );
    line-height: 1.71;
    margin: 1.6rem 0px 3.2rem;
  }
  .accept-btn {
    @include typography(
      $font-primary,
      $color: $container-upload-warning-modal-button-color,
      $size: 1.2rem,
      $weight: 600
    );
    line-height: 2.1;
    border-radius: 4px;
    border: none;
    font-family: "Inter";
  }
}
