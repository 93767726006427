#std_dimmer {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  z-index: 999999999;
  height: 100%;
  background-image: url("../../assets/icons/loading.svg");
  background-position: center center;
  background-size: 60px 60px;
  background-repeat: no-repeat;
}
